<template>
  <div v-if="helpDataFetched">
    <v-container v-if="!edit">
      <v-row no-gutters>
        <v-col cols="12" class="d-flex pr-2">
          <span v-if="inputType === 'color'" class="align-self-center">
            <v-chip class="ma-2 white--text" :color="rewrittenItem">{{
              rewrittenItem
            }}</v-chip>
          </span>

          <!--  rewrittenItem returns 'is-array' when its an array.
          in this case, we use list-rendering for chips component -->

          <span v-else-if="rewrittenItem === 'is-array'">
            <v-chip
              v-for="item in currentValue"
              :key="item.id"
              class="ml-1 mb-1"
            >
              {{ item.name }}
            </v-chip>
          </span>

          <img
            v-else-if="inputType === 'file'"
            :src="currentValue"
            style="max-height: 150px; max-width: 150px"
          />

          <v-row v-else-if="inputElement === 'textarea-md'">
            <v-col cols="11" class="d-flex pr-2">
              <v-card tile width="100%" max-width="100%" class="pa-6 ma-0">
                <vue-markdown
                  v-if="itemValue"
                  :source="itemValue"
                ></vue-markdown>
              </v-card>
            </v-col>

            <v-col cols="1" class="d-flex">
              <v-btn
                fab
                elevation="0"
                x-small
                color="gray lighten-4"
                class="ml-2"
                :disabled="disabledByPermission(permissionsWrite)"
                @click="editItem()"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-else-if="inputElement === 'textarea'">
            <v-col cols="12">
              {{ rewrittenItem }}
              <v-btn
                fab
                elevation="0"
                x-small
                color="gray lighten-4"
                class="ml-2"
                :disabled="disabledByPermission(permissionsWrite)"
                @click="editItem()"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="12">
              <span v-if="shownValue">{{ shownValue }}</span>
              <span v-else> {{ rewrittenItem }}</span>

              <v-btn
                fab
                elevation="0"
                x-small
                color="gray lighten-4"
                class="ml-2"
                :disabled="disabledByPermission(permissionsWrite)"
                @click="editItem()"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row no-gutters>
        <v-col
          v-if="
            itemName === 'isActive' ||
            itemName === 'isJmp' ||
            itemName === 'renewalAutomatic' ||
            itemName === 'isAdditionalOnly' ||
            itemName === 'isAdditionalHighlight' ||
            (itemName === 'isDemoRequestable' && inputElement === 'select')
          "
          cols="10"
          class="pr-2 d-flex"
        >
          <!-- 
          SONDERFALL: Wird immer verwendet, wenn itemName = isActive ist, da isActive immer Ja/Nein 1/0 ist.
          array MUST contain name + id 
        -->
          <v-select
            class="align-self-center"
            v-model="currentValue"
            :items="yesNoOptions"
            item-text="name"
            item-value="value"
            label="Auswahl"
            dense
          ></v-select>
        </v-col>

        <v-col
          v-else-if="inputElement === 'input'"
          cols="10"
          class="pr-2 d-flex"
        >
          <v-text-field
            v-if="inputType === 'text'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
            full-width
            hide-details
            dense
          ></v-text-field>
          <v-text-field
            v-else-if="inputType === 'email'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
            :rules="[rules.email]"
            full-width
            hide-details
            dense
          ></v-text-field>
          <v-text-field
            v-else-if="inputType === 'number'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
            full-width
            hide-details
            dense
          ></v-text-field>
          <v-text-field
            v-else-if="inputType === 'tel'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
            full-width
            hide-details
            dense
          ></v-text-field>
          <v-text-field
            v-else-if="inputType === 'url'"
            class="align-self-center p0 m0"
            v-model="currentValue"
            :type="inputType"
            :rules="[rules.url]"
            full-width
            hide-details
            dense
          ></v-text-field>

          <span v-else-if="inputType === 'file'" style="width: 100%">
            <img
              v-if="currentValue"
              :src="currentValue"
              style="max-height: 150px; max-width: 150px"
            />

            <v-file-input
              class="align-self-center p0 m0"
              v-model="fileValue"
              chips
              show-size
              label="File input"
              full-width
              hide-details
              dense
            ></v-file-input>
          </span>

          <div v-else-if="inputType === 'color'">
            <!-- colorpicker -->
            <v-color-picker
              v-model="currentValue"
              flat
              hide-mode-switch
              type="hexa"
              mode="hexa"
              elevation="5"
              dense
            ></v-color-picker>
          </div>
        </v-col>

        <v-col
          v-else-if="inputElement === 'select'"
          cols="10"
          class="pr-2 d-flex"
        >
          <!-- array MUST contain name + id -->
          <v-select
            class="align-self-center"
            v-model="currentValue"
            :items="itemsToSelect"
            :item-text="itemTextUsed"
            :item-value="itemValueUsed"
            label="Auswahl"
            full-width
            hide-details
            dense
          ></v-select>
        </v-col>

        <v-col
          v-else-if="inputElement === 'multiselect'"
          cols="10"
          class="pr-2 d-flex"
        >
          <!-- array MUST contain name + id -->
          <v-select
            class="align-self-center"
            v-model="currentValue"
            :items="itemsToSelect"
            :item-text="itemTextUsed"
            :item-value="itemValueUsed"
            label="Auswahl"
            chips
            multiple
            full-width
            hide-details
            dense
          ></v-select>
        </v-col>

        <v-col
          v-else-if="inputElement === 'textarea'"
          cols="10"
          class="pr-2 d-flex"
        >
          <v-textarea
            v-if="!counter"
            v-model="currentValue"
            name="input-7-1"
            label="Text"
            item-text="name"
            item-value="value"
            full-width
            hide-details
            auto-grow
            dense
          ></v-textarea>

          <v-textarea
            v-else
            v-model="currentValue"
            name="input-7-1"
            label="Text"
            item-text="name"
            item-value="value"
            :counter="counter"
            full-width
            hide-details
            auto-grow
            dense
          ></v-textarea>
        </v-col>

        <v-col v-else-if="inputElement === 'textarea-md'" cols="10">
          <v-container>
            <SimpleMdeEditor v-model="currentValue" :key="editorComponentKey" />
          </v-container>
        </v-col>

        <v-col
          v-else-if="inputElement === 'switch'"
          cols="10"
          class="pr-2 d-flex"
        >
          <v-switch v-model="currentValue" :label="switchLabel"></v-switch>
        </v-col>

        <v-col
          v-else-if="inputElement === 'datepicker'"
          cols="10"
          class="pr-2 d-flex"
        >
          <!-- Es gibt andere Wege: https://vuetifyjs.com/en/components/date-pickers/#orientation -->
          <v-date-picker
            v-model="currentValue"
            color="primary"
            elevation="5"
            show-adjacent-months
            full-width
            locale="de-de"
            dense
          ></v-date-picker>
        </v-col>

        <v-col
          v-else-if="inputElement === 'companypicker'"
          cols="10"
          class="pr-2 d-flex"
        >
          <v-select
            v-if="allCompanies"
            class="align-self-center"
            label="Bitte wähle eine Firma"
            v-model="currentValue"
            item-value="id"
            :items="allCompanies"
            outlined
            full-width
            hide-details
            dense
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.name }}
            </template>

            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item.name }}
            </template>
          </v-select>
        </v-col>

        <v-col
          v-else-if="inputElement === 'pagepicker'"
          cols="10"
          class="pr-2 d-flex"
        >
          <v-select
            v-if="allPages"
            class="align-self-center"
            label="Bitte wähle Seite"
            v-model="currentValue"
            item-value="id"
            :items="allPages"
            outlined
            full-width
            hide-details
            dense
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.title }} (#{{ data.item.id }})
            </template>

            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item.title }} (#{{ data.item.id }})
            </template>
          </v-select>
        </v-col>

        <v-col cols="2" class="d-flex justify-end">
          <EditButtons
            class="align-self-center"
            :ShowSafeButton="true"
            :ShowAbortButton="true"
            :Disabled="false"
            @emit="buttonAction"
          ></EditButtons
        ></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "@/mixins/api";
import EditButtons from "@/components/ui/EditButtons.vue";
import VueMarkdown from "vue-markdown/src/VueMarkdown"; // neuer Weg (s. oben)
import SimpleMdeEditor from "@/components/ui/SimpleMdeEditor";
export default {
  name: "DataPatcher",

  components: {
    EditButtons,
    VueMarkdown,
    SimpleMdeEditor,
  },

  props: [
    "itemValue",
    "itemName",
    "route",
    "inputElement",
    "inputType",
    "selectOptions",
    "itemId",
    "switchLabel",
    "permissionsWrite",
    "counter",
    "itemValueKey",
    "itemTextKey",
    "shownValue",
  ],

  data() {
    return {
      itemTextUsed: this.itemTextKey,
      itemValueUsed: this.itemValueKey,
      editorComponentKey: 0,
      preloader: false,
      helpDataFetched: false,
      edit: false,
      fileValue: null,
      currentValue: this.itemValue,
      currentName: this.itemName,
      currentType: this.inputElement,
      colorTypes: ["hex"],
      allCompanies: [] /* for companyPicker */,
      allPages: [] /* for pagePicker */,
      productTypes: this.getProductTypes,
      companyName: "",
      yesNoOptions: [
        {
          name: "Ja",
          value: "1",
        },
        {
          name: "Nein",
          value: "0",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: (value) => {
          const pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator
          return pattern.test(value) || "Invalid URL";
        },
      },
    };
  },

  created: async function () {
    /* get needed data (eg for selections) via api to ref Var */

    if (this.itemValueUsed === "" || !this.itemValueUsed) {
      this.itemValueUsed = "value";
    }

    if (this.itemTextUsed === "" || !this.itemTextUsed) {
      this.itemTextUsed = "name";
    }

    if (this.inputElement === "companypicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("companies"); // await data from mixin
      this.allCompanies = response.data.data;
      this.helpDataFetched = true;
    } else if (this.inputElement === "pagepicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("pages"); // await data from mixin
      this.allPages = response.data.data;
      this.helpDataFetched = true;
    } else if (this.itemName === "type_id") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("products/types"); // await data from mixin
      this.productTypes = response.data.data;
      this.helpDataFetched = true;
    } else {
      this.helpDataFetched = true;
    }
  },

  watch: {
    // keine ahnung warum, aber itemValue kommt beim reload später als currentValue gesetzt wird
    itemValue: function () {
      this.currentValue = this.itemValue;
    },
  },

  computed: {
    simpleArray() {
      let result = [];

      this.currentValue.forEach((item) => {
        let id = null;

        if (item.id) {
          id = item.id;
        } else {
          id = item;
        }

        result.push(id);
      });

      return result;
    },

    simplemde() {
      return this.$refs.markdownEditor.simplemde;
    },

    itemsToSelect() {
      return this.selectOptions; // prop
    },

    rewrittenItem: function () {
      /* Umschreiben bestimmter Werte direkt im Array.
        1/0 wird Ja/Nein etc. */

      let value = this.currentValue;

      if (Array.isArray(value)) {
        /* falls value ein Array ist, wird hier lediglich 'is-array' returned.
          In diesem Fall wird im Template mit Chips die Liste gerendert */
        console.log("return is array");
        return "is-array";
      }

      /* Ja/Nein */

      if (this.currentName === "isActive") {
        console.log(this.currentName);
        if (value === "0" || value === 0 || value === "Null") {
          value = "Nein";
        } else {
          value = "Ja";
        }
      }

      if (value === "female") {
        value = "Weiblich";
      } else if (value === "male") {
        value = "Männlich";
      } else if (value === "diverse") {
        value = "Divers";
      } else if (!value) {
        value = "-";
      } else if (value === "active") {
        value = "Aktiv";
      }

      /* Status of Pitches */

      if (value === "isOpen") {
        value = "Offen";
      } else if (value === "isContacted") {
        value = "Kontaktiert";
      } else if (value === "isInterested") {
        value = "Interessiert";
      } else if (value === "isScheduled") {
        value = "Terminiert";
      } else if (value === "offerSent") {
        value = "Angebot gesendet";
      } else if (value === "isInterested") {
        value = "Interessiert";
      } else if (value === "isLost") {
        value = "Verloren";
      } else if (value === "isWon") {
        value = "Gewonnen";
      } else if (!value) {
        value = "-";
      }

      /* Special-Case: Get Companyname by ID */

      if (this.currentName === "companies_id") {
        let that = this;

        Object.keys(this.allCompanies).forEach(function (k) {
          if (that.allCompanies[k].id === value) {
            value = that.allCompanies[k].name;
          }
        });
      }

      if (
        this.currentName === "legalForm" ||
        this.currentName === "businessCategory"
      ) {
        let that = this;

        Object.keys(this.selectOptions).forEach(function (k) {
          if (that.selectOptions[k].id === value) {
            value = that.selectOptions[k].name;
          }
        });
      }

      /* Special-Case: Get Pagename by ID */
      if (this.currentName === "pages_id") {
        let that = this;

        Object.keys(this.allPages).forEach(function (k) {
          if (that.allPages[k].id === value) {
            value = that.allPages[k].title;
          }
        });
      }

      /* Special-Case: Get productType by ID */

      if (this.currentName === "type_id") {
        let that = this;
        console.log(this.productTypes);
        Object.keys(this.productTypes).forEach(function (k) {
          console.log(that.productTypes[k].id);

          if (that.productTypes[k].id === value) {
            value = that.productTypes[k].name;
            console.log("type_id" + that.productTypes[k].name);
          }
        });
      }

      return value;
    },
  },

  methods: {
    nullNotAllowed(value) {
      if (value === null || value === "Null") {
        return "0";
      } else {
        return value;
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    generateValueArray(currentValue) {
      let result = [];

      currentValue.forEach((item) => {
        let id = null;

        if (item.value) {
          id = item.value;
        } else {
          id = item;
        }

        result.push(id);
      });

      return result;
    },

    async getCompanieName(id) {
      let response = await this.getRequest("companies/" + id); // await data from mixin
      return response.data.data.name;
    },

    editItem() {
      this.edit = true;
    },

    blurItem() {
      this.edit = false;
      this.currentValue = this.itemValue;
    },

    async buttonAction(value) {
      if (value.action === "editItem") {
        this.edit = true;
        this.editorComponentKey += 1;
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.currentValue = this.itemValue;
        this.editorComponentKey += 1;
      } else if (value.action === "safeItem") {
        if (this.currentValue && this.currentValue.hex) {
          this.currentValue = this.currentValue.hex;
        }

        const params = new URLSearchParams();
        params.append(this.itemName, this.currentValue);

        let addIdToRoute = "";
        if (this.itemId) {
          addIdToRoute = "/" + this.itemId;
        }

        if (this.inputType === "file") {
          // FILE -> DO POST

          var formData = new FormData(); // leer
          formData.append("file", this.fileValue);

          var body = null;

          this.preloader = true;
          let response = await this.postRequest(
            `${this.route}${addIdToRoute}`,
            body,
            formData
          ); // await data from mixin -> Formdata: title, prio,
          this.preloader = false;

          console.log(
            "SET this.currentValue TO " + response.data.data.mediaLink
          );
          this.currentValue = response.data.data.mediaLink;

          this.$emit("emit", {
            action: "itemPatched",
            name: this.itemName,
            value: response.data.data.mediaLink,
          }); // rückgabe an parent

          if (response) {
            this.edit = false;
            this.$root.snackbarGlobal.snackbar = true;
            this.$root.snackbarGlobal.snackbarText =
              "Der Datensatz wurde geupdated!";
            this.$root.snackbarGlobal.snackbarColor = "primary";
            this.$root.snackbarGlobal.snackbarTimeout = 5000;
          }
        } else {
          // PATCH NORMAL

          this.preloader = true;
          let response = await this.patch(
            `${this.route}${addIdToRoute}`,
            params
          ); // await data from mixin -> Formdata: title, prio,
          this.preloader = false;

          /* ARRAY SONDERFÄLLE 
          Da ich für Arrays nur die Ids übergebe, muss ich für die Reaktivität das currentValue aus dem Response verwenden.
          Nur im Response ist die id/name Kombi drin, die ich für die Ausgabe brauche
          */
          if (this.itemName === "branches") {
            this.currentValue = response.data.data.branches;
          } else if (this.itemName === "categories") {
            this.currentValue = response.data.data.categories;
          } else if (this.itemName === "jmpCategories") {
            this.currentValue = response.data.data.jmpCategories;
          }

          this.$emit("emit", {
            action: "itemPatched",
            name: this.itemName,
            value: this.currentValue,
            response: response,
          }); // rückgabe an parent

          if (response) {
            this.edit = false;
            this.$root.snackbarGlobal.snackbar = true;
            this.$root.snackbarGlobal.snackbarText =
              "Der Datensatz wurde geupdated!";
            this.$root.snackbarGlobal.snackbarColor = "green";
            this.$root.snackbarGlobal.snackbarTimeout = 5000;
          }
        }
      }
    },
  },

  mixins: [api],
};
</script>

<style scoped>
@import "~simplemde/dist/simplemde.min.css";

.v-application ol,
.v-application ul {
  margin: 20px 0 !important;
}

.v-application p {
  margin-bottom: 12px;
  margin-top: 12px;
}
</style>
