<template>
  <div>
    <div v-if="!Disabled">
      <v-btn
        class="mx-1 white--text"
        v-if="ShowEditButton"
        fab
        x-small
        color="green"
        elevation="0"
        :loading="loading"
        @click="emitStuff('editItem')"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>

      <v-btn
        class="mx-1 white--text"
        v-if="ShowSafeButton"
        fab
        x-small
        color="primary"
        elevation="0"
        :loading="loading"
        @click="emitStuff('safeItem')"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>

      <v-btn
        class="mx-1 white--text"
        v-if="ShowCopyButton"
        fab
        x-small
        color="warning"
        elevation="0"
        :loading="loading"
        @click="emitStuff('copyItem')"
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>

      <v-btn
        class="mx-1 white--text"
        v-if="ShowDuplicateButton"
        fab
        x-small
        color="warning"
        elevation="0"
        :loading="loading"
        @click="emitStuff('duplicateItem')"
      >
        <v-icon>mdi-content-duplicate</v-icon>
      </v-btn>

      <v-btn
        class="mx-1 white--text"
        v-if="ShowDeleteButton"
        fab
        x-small
        color="error"
        elevation="0"
        :loading="loading"
        @click="emitStuff('deleteItem')"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>

      <v-btn
        class="mx-1 white--text"
        v-if="ShowAbortButton"
        fab
        x-small
        color="secondary"
        elevation="0"
        :loading="loading"
        @click="emitStuff('abortItem')"
      >
        <v-icon>mdi-cancel</v-icon>
      </v-btn>

      <v-btn
        class="mx-1 white--text white--text"
        v-if="ShowActivateButton"
        fab
        x-small
        color="light-green"
        elevation="0"
        :loading="loading"
        @click="emitStuff('activateItem')"
      >
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn>
    </div>

    <div v-else>
      <v-btn
        class="mx-1"
        v-if="ShowEditButton"
        fab
        x-small
        color="primary"
        elevation="0"
        :loading="loading"
        style="opacity: 0.2; cursor: not-allowed"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>

      <v-btn
        class="mx-1"
        v-if="ShowSafeButton"
        fab
        x-small
        color="primary"
        elevation="0"
        :loading="loading"
        style="opacity: 0.2; cursor: not-allowed"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>

      <v-btn
        class="mx-1"
        v-if="ShowCopyButton"
        fab
        x-small
        color="warning"
        elevation="0"
        :loading="loading"
        style="opacity: 0.2; cursor: not-allowed"
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>

      <v-btn
        class="mx-1"
        v-if="ShowDuplicateButton"
        fab
        x-small
        color="warning"
        elevation="0"
        :loading="loading"
        style="opacity: 0.2; cursor: not-allowed"
      >
        <v-icon>mdi-content-duplicate</v-icon>
      </v-btn>

      <v-btn
        class="mx-1"
        v-if="ShowDeleteButton"
        fab
        x-small
        color="error"
        elevation="0"
        :loading="loading"
        style="opacity: 0.2; cursor: not-allowed"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>

      <v-btn
        class="mx-1"
        v-if="ShowAbortButton"
        fab
        x-small
        color="secondary"
        elevation="0"
        :loading="loading"
        style="opacity: 0.2; cursor: not-allowed"
      >
        <v-icon>mdi-cancel</v-icon>
      </v-btn>

      <v-btn
        class="mx-1 white--text"
        v-if="ShowActivateButton"
        fab
        x-small
        color="light-green"
        elevation="0"
        :loading="loading"
        style="opacity: 0.2; cursor: not-allowed"
      >
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditButtons",
  components: {},
  props: [
    "ShowEditButton",
    "ShowDeleteButton",
    "ShowCopyButton",
    "ShowDuplicateButton",
    "ShowSafeButton",
    "ShowAbortButton",
    "ShowActivateButton",
    "Disabled",
    "itemId",
    "itemIndex",
    "loading",
  ],

  methods: {
    emitStuff(action) {
      console.log("emit from EditButtons: " + action);
      this.$emit("emit", {
        action: action,
        itemId: this.itemId,
        itemIndex: this.itemIndex,
      });
    },

    /* editItem () {
      console.log("emit editItem");
      this.$emit('emit', { action: 'editItem', itemId: this.itemId, itemIndex: this.itemIndex  })
    },
    safeItem () {
      this.$emit('emit', { action: 'safeItem', itemId: this.itemId })
    },
    deleteItem () {
      this.$emit('emit', { action: 'deleteItem', itemId: this.itemId, itemIndex: this.itemIndex })
    },
    copyItem () {
      this.$emit('emit', { action: 'copyItem', itemId: this.itemId })
    },
    duplicateItem () {
      this.$emit('emit', { action: 'duplicateItem', itemId: this.itemId })
    },
    abortItem () {
      this.$emit('emit', { action: 'abortItem', itemId: this.itemId })
    },
    activateItem () {
      this.$emit('emit', { action: 'activateItem', itemId: this.itemId })
    }, */
  },
};
</script>
